export default {
    schema: 'iglu:be.vrt/context_banner/jsonschema/1-1-0',
    data: {
        bannertitle: '$bati',
        // optional
        componentname: '$bana',
        componentid: '$baid',
        bannersubtitle: '$bast',
        bannertype: '$baty',
        componentposition: '$bacp',
        componentcount: '$bacc',
        calculationid: '$baci',
    },
};
