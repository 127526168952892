import linkContextTemplate from './contexts/link_1-1-0';
import targetContextTemplate from './contexts/target_1-1-0';
import searchContextTemplate from './contexts/search_1-1-2';

const templates = {};

templates.link_click_1_1_0 = {
    event: {
        schema: 'iglu:be.vrt/link_click/jsonschema/1-1-0',
        data: {
            timestamp: '$now()',
        },
    },
    context: [
        linkContextTemplate,
        targetContextTemplate,
        searchContextTemplate,
    ],
};

export default templates;
