import listContextTemplate from './contexts/list_1-1-0';
import listElementContextTemplate from './contexts/listelement_1-1-0';
import featureContextTemplate from './contexts/feature_1-1-0';
import targetContextTemplate from './contexts/target_1-1-0';
import searchContextTemplate from './contexts/search_1-1-2';

const templates = {};

templates.list_impression_1_1_0 = {
    event: {
        schema: 'iglu:be.vrt/list_impression/jsonschema/1-1-0',
        data: {
            timestamp: '$now()',
        },
    },
    context: [
        listContextTemplate,
        listElementContextTemplate,
        featureContextTemplate,
        searchContextTemplate,
    ],
};

templates.list_click_1_1_0 = {
    event: {
        schema: 'iglu:be.vrt/list_click/jsonschema/1-1-0',
        data: {
            timestamp: '$now()',
        },
    },
    context: [
        listContextTemplate,
        listElementContextTemplate,
        featureContextTemplate,
        targetContextTemplate,
        searchContextTemplate,
    ],
};

export default templates;
