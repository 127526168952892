export default {
    schema: 'iglu:be.vrt/context_content_media/jsonschema/1-1-0',
    data: {
        contentname: '$cmcn',
        contentid: '$cmid',
        contentlength: '$cmle',
        streamtype: '$cmty',
        contenttype: '$cmct',
        contentsubtype: '$cmst',
        // optional
        publishtimestamp: '$cmpt',
        contentlabels: '$cmcl',
    },
};
