export default {
    schema: 'iglu:be.vrt/context_feature/jsonschema/1-1-0',
    data: {
        featurekey: '$feke',
        featurevalue: '$feva',
        featuresource: '$fesr',
        featureruleid: '$feri',
        featureextra: '$feex',
    },
};
