export default {
    schema: 'iglu:be.vrt/context_content_article/jsonschema/1-1-0',
    data: {
        articletype: '$caat',

        // optional
        articleregion: '$caar',
        articlewordcount: '$caawc',
        articlevideocount: '$caavc',
        articleaudiocount: '$caaac',
        publishtimestamp: '$capts',
        updatetimestamp: '$cauts',
    },
};
