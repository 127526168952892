export default {
    schema: 'iglu:be.vrt/context_banner/jsonschema/1-1-4',
    data: {
        bannertitle: '$bati',
        // optional
        bannersubtitle: '$bast',
        bannertype: '$baty',
        banneradvertiser: '$baad',
        componentposition: '$bacp',
        componentcount: '$bacc',
        componentname: '$bana',
        componentid: '$baid',
        containerid: '$bacoid',
        sectionposition: '$basi',
        calculationid: '$baci',
        imagesource: '$bais',
    },
};
