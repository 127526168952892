export default {
    schema: 'iglu:be.vrt/context_list/jsonschema/1-1-0',
    data: {
        listtitle: '$liti',
        // optional
        listtype: '$lity',
        listorientation: '$lior',
        componentposition: '$licp',
        componentcount: '$licc',
        componentname: '$licn',
        componentid: '$liid',
        calculationid: '$lici',
    },
};
