export default {
    schema: 'iglu:be.vrt/context_link/jsonschema/1-1-0',
    data: {
        linktitle: '$linti',
        // optional
        linktype: '$linty',
        componentname: '$lincn',
        componentid: '$linid',
    },
};
