import contentmediaContextTemplate from './contexts/content-media_1-1-0';
import mediasessionContextTemplate from './contexts/mediasession_1-1-0';
import playerContextTemplate from './contexts/player_1-1-0';
import mediaqoeContextTemplate from './contexts/mediaqoe_1-1-0';

const templates = {};

templates.media_bufferstart_1_1_0 = {
    event: {
        schema: 'iglu:be.vrt/media_bufferstart/jsonschema/1-1-0',
        data: {
            timestamp: '$now()',
        },
    },
    context: [
        mediasessionContextTemplate,
        contentmediaContextTemplate,
        playerContextTemplate,
        mediaqoeContextTemplate,
    ],
};

templates.media_bufferend_1_1_0 = {
    event: {
        schema: 'iglu:be.vrt/media_bufferend/jsonschema/1-1-0',
        data: {
            timestamp: '$now()',
        },
    },
    context: [
        mediasessionContextTemplate,
        contentmediaContextTemplate,
        playerContextTemplate,
        mediaqoeContextTemplate,
    ],
};

templates.media_seekstart_1_1_0 = {
    event: {
        schema: 'iglu:be.vrt/media_seekstart/jsonschema/1-1-0',
        data: {
            timestamp: '$now()',
        },
    },
    context: [
        mediasessionContextTemplate,
        contentmediaContextTemplate,
        playerContextTemplate,
        mediaqoeContextTemplate,
    ],
};

templates.media_seekend_1_1_0 = {
    event: {
        schema: 'iglu:be.vrt/media_seekend/jsonschema/1-1-0',
        data: {
            timestamp: '$now()',
        },
    },
    context: [
        mediasessionContextTemplate,
        contentmediaContextTemplate,
        playerContextTemplate,
        mediaqoeContextTemplate,
    ],
};

export default templates;
